import { PropsWithChildren } from 'react';
import { Box, Breadcrumbs, Title } from '@mantine/core';

type Props = {
    breadcrumbs?: { title: string }[];
};

export const PageContainer: React.FC<PropsWithChildren<Props>> = ({ children, breadcrumbs }) => {
    return (
        <Box display="flex" style={{ width: '100%', flexDirection: 'column' }}>
            {breadcrumbs && breadcrumbs.length > 0 && (
                <Box mb="sm" display="flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Breadcrumbs>
                        {breadcrumbs.map((item, index) => (
                            <Title order={3} key={index}>
                                {item.title}
                            </Title>
                        ))}
                    </Breadcrumbs>
                </Box>
            )}
            {children}
        </Box>
    );
};
