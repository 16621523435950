/* tslint:disable */
/* eslint-disable */
/**
 * NK Solution Core API
 * OpenAPI backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AnalysesClaimsPost200Response
 */
export interface AnalysesClaimsPost200Response {
    /**
     * 
     * @type {string}
     * @memberof AnalysesClaimsPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<AnalysesClaimsPost200ResponseClaimsInner>}
     * @memberof AnalysesClaimsPost200Response
     */
    'claims'?: Array<AnalysesClaimsPost200ResponseClaimsInner>;
    /**
     * 
     * @type {string}
     * @memberof AnalysesClaimsPost200Response
     */
    'conclusion'?: string;
}
/**
 * 
 * @export
 * @interface AnalysesClaimsPost200ResponseClaimsInner
 */
export interface AnalysesClaimsPost200ResponseClaimsInner {
    /**
     * 
     * @type {string}
     * @memberof AnalysesClaimsPost200ResponseClaimsInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysesClaimsPost200ResponseClaimsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysesClaimsPost200ResponseClaimsInner
     */
    'analysis'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnalysesClaimsPost200ResponseClaimsInner
     */
    'valid'?: boolean;
}
/**
 * 
 * @export
 * @interface AnalysesClaimsPostRequest
 */
export interface AnalysesClaimsPostRequest {
    /**
     * 
     * @type {Array<AnalysesClaimsPostRequestClaimsInner>}
     * @memberof AnalysesClaimsPostRequest
     */
    'claims'?: Array<AnalysesClaimsPostRequestClaimsInner>;
}
/**
 * @type AnalysesClaimsPostRequestClaimsInner
 * @export
 */
export type AnalysesClaimsPostRequestClaimsInner = Claim | string;

/**
 * 
 * @export
 * @interface AnalysesInvoicesPost200Response
 */
export interface AnalysesInvoicesPost200Response {
    /**
     * 
     * @type {string}
     * @memberof AnalysesInvoicesPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysesInvoicesPost200Response
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysesInvoicesPost200Response
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysesInvoicesPost200Response
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysesInvoicesPost200Response
     */
    'currencyCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalysesInvoicesPost200Response
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnalysesInvoicesPost200Response
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface AnalysesInvoicesPost400Response
 */
export interface AnalysesInvoicesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof AnalysesInvoicesPost400Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface Claim
 */
export interface Claim {
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Claim
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'currencyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'submissionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Claim
     */
    'requester'?: string;
}
/**
 * 
 * @export
 * @interface ExpensesExportsPostRequest
 */
export interface ExpensesExportsPostRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExpensesExportsPostRequest
     */
    'claims'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ThreadsPost200Response
 */
export interface ThreadsPost200Response {
    /**
     * 
     * @type {string}
     * @memberof ThreadsPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadsPost200Response
     */
    'threadId'?: string;
}
/**
 * 
 * @export
 * @interface ThreadsPostRequest
 */
export interface ThreadsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ThreadsPostRequest
     */
    'topic': ThreadsPostRequestTopicEnum;
}

export const ThreadsPostRequestTopicEnum = {
    Product: 'product'
} as const;

export type ThreadsPostRequestTopicEnum = typeof ThreadsPostRequestTopicEnum[keyof typeof ThreadsPostRequestTopicEnum];

/**
 * 
 * @export
 * @interface ThreadsThreadIdMessagesPost200Response
 */
export interface ThreadsThreadIdMessagesPost200Response {
    /**
     * 
     * @type {string}
     * @memberof ThreadsThreadIdMessagesPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreadsThreadIdMessagesPost200Response
     */
    'reply'?: string;
}
/**
 * 
 * @export
 * @interface ThreadsThreadIdMessagesPostRequest
 */
export interface ThreadsThreadIdMessagesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ThreadsThreadIdMessagesPostRequest
     */
    'content': string;
}

/**
 * AnalysisApi - axios parameter creator
 * @export
 */
export const AnalysisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Analyse expense claims validity
         * @param {AnalysesClaimsPostRequest} analysesClaimsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesClaimsPost: async (analysesClaimsPostRequest: AnalysesClaimsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'analysesClaimsPostRequest' is not null or undefined
            assertParamExists('analysesClaimsPost', 'analysesClaimsPostRequest', analysesClaimsPostRequest)
            const localVarPath = `/analyses/claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-nk-api-key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(analysesClaimsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Analyse an invoice image
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesInvoicesPost: async (image: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('analysesInvoicesPost', 'image', image)
            const localVarPath = `/analyses/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-nk-api-key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisApi - functional programming interface
 * @export
 */
export const AnalysisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalysisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Analyse expense claims validity
         * @param {AnalysesClaimsPostRequest} analysesClaimsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesClaimsPost(analysesClaimsPostRequest: AnalysesClaimsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysesClaimsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesClaimsPost(analysesClaimsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnalysisApi.analysesClaimsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Analyse an invoice image
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysesInvoicesPost(image: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysesInvoicesPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analysesInvoicesPost(image, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnalysisApi.analysesInvoicesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AnalysisApi - factory interface
 * @export
 */
export const AnalysisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalysisApiFp(configuration)
    return {
        /**
         * 
         * @summary Analyse expense claims validity
         * @param {AnalysesClaimsPostRequest} analysesClaimsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesClaimsPost(analysesClaimsPostRequest: AnalysesClaimsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AnalysesClaimsPost200Response> {
            return localVarFp.analysesClaimsPost(analysesClaimsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Analyse an invoice image
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysesInvoicesPost(image: File, options?: RawAxiosRequestConfig): AxiosPromise<AnalysesInvoicesPost200Response> {
            return localVarFp.analysesInvoicesPost(image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisApi - object-oriented interface
 * @export
 * @class AnalysisApi
 * @extends {BaseAPI}
 */
export class AnalysisApi extends BaseAPI {
    /**
     * 
     * @summary Analyse expense claims validity
     * @param {AnalysesClaimsPostRequest} analysesClaimsPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public analysesClaimsPost(analysesClaimsPostRequest: AnalysesClaimsPostRequest, options?: RawAxiosRequestConfig) {
        return AnalysisApiFp(this.configuration).analysesClaimsPost(analysesClaimsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Analyse an invoice image
     * @param {File} image Image file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public analysesInvoicesPost(image: File, options?: RawAxiosRequestConfig) {
        return AnalysisApiFp(this.configuration).analysesInvoicesPost(image, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssitantApi - axios parameter creator
 * @export
 */
export const AssitantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new thread
         * @param {ThreadsPostRequest} threadsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        threadsPost: async (threadsPostRequest: ThreadsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadsPostRequest' is not null or undefined
            assertParamExists('threadsPost', 'threadsPostRequest', threadsPostRequest)
            const localVarPath = `/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-nk-api-key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(threadsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send a message to a thread
         * @param {string} threadId 
         * @param {ThreadsThreadIdMessagesPostRequest} threadsThreadIdMessagesPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        threadsThreadIdMessagesPost: async (threadId: string, threadsThreadIdMessagesPostRequest: ThreadsThreadIdMessagesPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('threadsThreadIdMessagesPost', 'threadId', threadId)
            // verify required parameter 'threadsThreadIdMessagesPostRequest' is not null or undefined
            assertParamExists('threadsThreadIdMessagesPost', 'threadsThreadIdMessagesPostRequest', threadsThreadIdMessagesPostRequest)
            const localVarPath = `/threads/{threadId}/messages`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-nk-api-key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(threadsThreadIdMessagesPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssitantApi - functional programming interface
 * @export
 */
export const AssitantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssitantApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new thread
         * @param {ThreadsPostRequest} threadsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async threadsPost(threadsPostRequest: ThreadsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.threadsPost(threadsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssitantApi.threadsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Send a message to a thread
         * @param {string} threadId 
         * @param {ThreadsThreadIdMessagesPostRequest} threadsThreadIdMessagesPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async threadsThreadIdMessagesPost(threadId: string, threadsThreadIdMessagesPostRequest: ThreadsThreadIdMessagesPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadsThreadIdMessagesPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.threadsThreadIdMessagesPost(threadId, threadsThreadIdMessagesPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssitantApi.threadsThreadIdMessagesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssitantApi - factory interface
 * @export
 */
export const AssitantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssitantApiFp(configuration)
    return {
        /**
         * Create a new thread
         * @param {ThreadsPostRequest} threadsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        threadsPost(threadsPostRequest: ThreadsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ThreadsPost200Response> {
            return localVarFp.threadsPost(threadsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Send a message to a thread
         * @param {string} threadId 
         * @param {ThreadsThreadIdMessagesPostRequest} threadsThreadIdMessagesPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        threadsThreadIdMessagesPost(threadId: string, threadsThreadIdMessagesPostRequest: ThreadsThreadIdMessagesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ThreadsThreadIdMessagesPost200Response> {
            return localVarFp.threadsThreadIdMessagesPost(threadId, threadsThreadIdMessagesPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssitantApi - object-oriented interface
 * @export
 * @class AssitantApi
 * @extends {BaseAPI}
 */
export class AssitantApi extends BaseAPI {
    /**
     * Create a new thread
     * @param {ThreadsPostRequest} threadsPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssitantApi
     */
    public threadsPost(threadsPostRequest: ThreadsPostRequest, options?: RawAxiosRequestConfig) {
        return AssitantApiFp(this.configuration).threadsPost(threadsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send a message to a thread
     * @param {string} threadId 
     * @param {ThreadsThreadIdMessagesPostRequest} threadsThreadIdMessagesPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssitantApi
     */
    public threadsThreadIdMessagesPost(threadId: string, threadsThreadIdMessagesPostRequest: ThreadsThreadIdMessagesPostRequest, options?: RawAxiosRequestConfig) {
        return AssitantApiFp(this.configuration).threadsThreadIdMessagesPost(threadId, threadsThreadIdMessagesPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExpenseApi - axios parameter creator
 * @export
 */
export const ExpenseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Upload an invoice image to create an expense claim
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expensesClaimsPost: async (image: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('expensesClaimsPost', 'image', image)
            const localVarPath = `/expenses/claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-nk-api-key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export expense claim
         * @param {ExpensesExportsPostRequest} expensesExportsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expensesExportsPost: async (expensesExportsPostRequest: ExpensesExportsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expensesExportsPostRequest' is not null or undefined
            assertParamExists('expensesExportsPost', 'expensesExportsPostRequest', expensesExportsPostRequest)
            const localVarPath = `/expenses/exports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-nk-api-key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(expensesExportsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpenseApi - functional programming interface
 * @export
 */
export const ExpenseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExpenseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Upload an invoice image to create an expense claim
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expensesClaimsPost(image: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysesInvoicesPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expensesClaimsPost(image, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpenseApi.expensesClaimsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Export expense claim
         * @param {ExpensesExportsPostRequest} expensesExportsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expensesExportsPost(expensesExportsPostRequest: ExpensesExportsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalysesInvoicesPost400Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expensesExportsPost(expensesExportsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExpenseApi.expensesExportsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExpenseApi - factory interface
 * @export
 */
export const ExpenseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExpenseApiFp(configuration)
    return {
        /**
         * 
         * @summary Upload an invoice image to create an expense claim
         * @param {File} image Image file to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expensesClaimsPost(image: File, options?: RawAxiosRequestConfig): AxiosPromise<AnalysesInvoicesPost200Response> {
            return localVarFp.expensesClaimsPost(image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export expense claim
         * @param {ExpensesExportsPostRequest} expensesExportsPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expensesExportsPost(expensesExportsPostRequest: ExpensesExportsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AnalysesInvoicesPost400Response> {
            return localVarFp.expensesExportsPost(expensesExportsPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExpenseApi - object-oriented interface
 * @export
 * @class ExpenseApi
 * @extends {BaseAPI}
 */
export class ExpenseApi extends BaseAPI {
    /**
     * 
     * @summary Upload an invoice image to create an expense claim
     * @param {File} image Image file to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseApi
     */
    public expensesClaimsPost(image: File, options?: RawAxiosRequestConfig) {
        return ExpenseApiFp(this.configuration).expensesClaimsPost(image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export expense claim
     * @param {ExpensesExportsPostRequest} expensesExportsPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpenseApi
     */
    public expensesExportsPost(expensesExportsPostRequest: ExpensesExportsPostRequest, options?: RawAxiosRequestConfig) {
        return ExpenseApiFp(this.configuration).expensesExportsPost(expensesExportsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



