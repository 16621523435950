import { PageContainer } from '@/components/PageContainer/PageContainer';
import ProductAssistantChat from './ProductAssistantChat/ProductAssistantChat';

export function ProductAssistant() {
    return (
        <PageContainer breadcrumbs={[{ title: 'Product' }, { title: 'Assistant' }]}>
            <ProductAssistantChat />
        </PageContainer>
    );
}
