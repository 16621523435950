import React, { useEffect, useRef, useState } from 'react';
import { IconArrowRight } from '@tabler/icons-react';
import Markdown from 'react-markdown';
import { ActionIcon, Box, Container, Group, Skeleton, Text, TextInput, useMantineTheme } from '@mantine/core';
import { AssitantApi, Configuration } from '@/api';
import { capture } from '@/handlers/error';
import { useAuth } from '@/providers/auth/AuthProvider';
import { Typing } from '../components/Typing/Typing';
import styles from './ProductAssistantChat.module.css';

type Role = 'user' | 'assistant' | 'code' | 'typing';

type MessageProps = {
    role: Role;
    text: string;
};

const UserMessage = ({ text }: { text: string }) => {
    return <div className={styles.userMessage}>{text}</div>;
};

const AssistantMessage = ({ text }: { text: string }) => {
    return (
        <div className={styles.assistantMessage}>
            <Markdown>{text}</Markdown>
        </div>
    );
};

const CodeMessage = ({ text }: { text: string }) => {
    return (
        <div className={styles.codeMessage}>
            {text.split('\n').map((line, index) => (
                <div key={index}>
                    <span>{`${index + 1}. `}</span>
                    {line}
                </div>
            ))}
        </div>
    );
};

const Message = ({ role, text }: MessageProps) => {
    switch (role) {
        case 'user':
            return <UserMessage text={text} />;
        case 'assistant':
            return <AssistantMessage text={text} />;
        case 'code':
            return <CodeMessage text={text} />;
        case 'typing':
            return <Typing />;
        default:
            return null;
    }
};

const ProductAssistantChat = () => {
    // provider
    const { currentUser } = useAuth();

    // ui
    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState<MessageProps[]>([]);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [threadId, setThreadId] = useState('');
    const theme = useMantineTheme();

    // automatically scroll to bottom of chat
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // create a new threadID when chat component created
    useEffect(() => {
        const createThread = async () => {
            try {
                const token = await currentUser?.getIdToken();
                if (!token) {
                    return;
                }
                const api = new AssitantApi(new Configuration({ accessToken: token }));
                const res = await api.threadsPost({ topic: 'product' });
                if (res.data.threadId) {
                    setThreadId(res.data.threadId);
                    setMessages([]);
                }
            } catch (error) {
                capture(error);
            }
        };
        createThread();
    }, [currentUser]);

    const sendMessage = async (threadId: string, text: string) => {
        try {
            const token = await currentUser?.getIdToken();
            if (!token) {
                return;
            }
            const api = new AssitantApi(new Configuration({ accessToken: token }));
            const res = await api.threadsThreadIdMessagesPost(threadId, { content: text });
            appendMessage('assistant', res.data?.reply || 'Unable to process your inquiry. Please contact support for further assistance.');
        } catch (error) {
            capture(error);
        } finally {
            handleRunCompleted();
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!userInput.trim()) {
            return;
        }

        setMessages((prevMessages) => [...prevMessages, { role: 'user', text: userInput }, { role: 'typing', text: '' }]);
        sendMessage(threadId, userInput);
        setUserInput('');
        setInputDisabled(true);
        scrollToBottom();
    };

    // handleRunCompleted - re-enable the input form
    const handleRunCompleted = () => {
        setInputDisabled(false);
        setMessages((prevMessages) => {
            if (prevMessages[prevMessages.length - 1]?.role === 'typing') {
                return [...prevMessages.slice(0, -1)];
            }
            return prevMessages;
        });
    };

    /*
    =======================
    === Utility Helpers ===
    =======================
    */

    const appendMessage = (role: Role, text: string) => {
        setMessages((prevMessages) => {
            if (prevMessages[prevMessages.length - 1]?.role === 'typing') {
                return [...prevMessages.slice(0, -1), { role, text }];
            }

            return [...prevMessages, { role, text }];
        });
    };

    return (
        <>
            <Group className={styles.threadContainer} gap="xs">
                <Text size="sm" fw={700}>
                    THREAD
                </Text>
                {threadId ? (
                    <Text size="sm" c="gray">
                        {threadId}
                    </Text>
                ) : (
                    <Skeleton width={240} height={16} />
                )}
            </Group>
            <Box style={{ flex: 1, position: 'relative' }} mb="lg">
                <Container size="sm" className={styles.chatContainer}>
                    <div className={styles.messages}>
                        {messages.map((msg, index) => (
                            <Message key={index} role={msg.role} text={msg.text} />
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <form onSubmit={handleSubmit} className={styles.inputForm}>
                        <TextInput
                            value={userInput}
                            radius="xl"
                            size="lg"
                            placeholder="Enter your question"
                            rightSectionWidth={48}
                            rightSection={
                                <ActionIcon size={38} radius="xl" color={theme.primaryColor} variant="filled" disabled={inputDisabled || !userInput.trim() || !threadId} type="submit">
                                    <IconArrowRight size={22} stroke={2} />
                                </ActionIcon>
                            }
                            flex="auto"
                            onChange={(e) => setUserInput(e.currentTarget.value)}
                        />
                    </form>
                </Container>
            </Box>
        </>
    );
};

export default ProductAssistantChat;
