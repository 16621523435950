import { useState } from 'react';
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import { Anchor, Center, Modal, Table, Text, useModalsStack } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useMediaQuery } from '@mantine/hooks';
import { AnalysisApi, Configuration } from '@/api';
import { ImageDropzone } from '@/components/ImageDropzone/ImageDropzone';
import { ImagePreviewModal } from '@/components/ImagePreviewModal/ImagePreviewModal';
import { capture } from '@/handlers/error';
import { useAuth } from '@/providers/auth/AuthProvider';

type Element = {
    name?: string;
    valid?: boolean;
    description?: string;
    category?: string;
    currencyCode?: string;
    amount?: number;
    date?: string;
    file?: FileWithPath;
};

export function InvoiceUpload() {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width: 50em)');

    // Modal state
    const stack = useModalsStack(['result', 'preview-file']);
    const [elements, setElements] = useState<Element[]>([]);
    const [previewFile, setPreviewFile] = useState<FileWithPath | undefined>(undefined);

    const header = () => (
        <Table.Tr>
            <Table.Th>Filename</Table.Th>
            <Table.Th />
            <Table.Th>Summary</Table.Th>
            <Table.Th>Category</Table.Th>
            <Table.Th>Currency</Table.Th>
            <Table.Th>Amount</Table.Th>
            <Table.Th>Date</Table.Th>
        </Table.Tr>
    );

    const rows = (elements: Element[]) =>
        elements.map((element) => (
            <Table.Tr key={element.name}>
                <Table.Td>
                    {element.file ? (
                        <Anchor
                            fz="sm"
                            onClick={() => {
                                setPreviewFile(element.file);
                                stack.open('preview-file');
                            }}
                        >
                            {element.file.name}
                        </Anchor>
                    ) : (
                        element.name
                    )}
                </Table.Td>
                <Table.Td>
                    <Center>{element.valid ? <IconCircleCheck color="green" size={20} /> : <IconCircleX color="red" size={20} />}</Center>
                </Table.Td>
                <Table.Td>{element.description}</Table.Td>
                <Table.Td>{element.category}</Table.Td>
                <Table.Td>{element.currencyCode}</Table.Td>
                <Table.Td>
                    <Text c="teal" size="sm">
                        {element.amount}
                    </Text>
                </Table.Td>
                <Table.Td>{element.date}</Table.Td>
            </Table.Tr>
        ));

    const onUpload = async (files: FileWithPath[]) => {
        setLoading(true);
        try {
            if (!currentUser) {
                capture(new Error('User is not authenticated'));
                return;
            }

            const token = await currentUser.getIdToken();
            const api = new AnalysisApi(new Configuration({ accessToken: token }));
            const chunkSize = 5;
            const elements: Element[] = [];
            for (let i = 0; i < files.length; i += chunkSize) {
                const chunk = files.slice(i, i + chunkSize);
                elements.push(
                    ...(await Promise.all(
                        chunk.map((file) =>
                            api
                                .analysesInvoicesPost(file)
                                .then((response) => ({ ...response.data, file }))
                                .catch((error) => ({ description: `${error}`, valid: false, file }))
                        )
                    ))
                );
            }
            setElements(elements);
            stack.open('result');
        } catch (error) {
            capture(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <ImageDropzone style={{ width: '100%', alignContent: 'center' }} onDrop={onUpload} loading={loading} />
            <Modal.Stack>
                <ImagePreviewModal {...stack.register('preview-file')} file={previewFile} />
                <Modal
                    {...stack.register('result')}
                    title={
                        <Text fw={700} mr="md">
                            Result
                        </Text>
                    }
                    centered
                    fullScreen={isMobile}
                    styles={{
                        body: {
                            padding: isMobile ? 0 : undefined,
                        },
                    }}
                    size="auto"
                    transitionProps={{ duration: 200, transition: 'fade' }}
                >
                    <Table stickyHeader stickyHeaderOffset={60} striped>
                        <Table.Thead>{header()}</Table.Thead>
                        <Table.Tbody>{rows(elements)}</Table.Tbody>
                    </Table>
                </Modal>
            </Modal.Stack>
        </>
    );
}
